import React, { PureComponent, createRef } from 'react';
import classNames from 'classnames';

import lazyImageObserver from './lazyImageObserver';

// Chrome 75 开始支持原生的 <img loading="lazy" />
const isSupportNativeLoading = Boolean('loading' in HTMLImageElement.prototype);

class LazyImage extends PureComponent {

    static removeProtocol(src) {

        if (!src) {
            return null;
        }

        return src.replace(/^https?:/, '');
    }

    constructor(props) {
        super(props);
        this.el = createRef();
    }

    componentDidMount() {
        this.observer();
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.src && this.props.src) {
            this.observer();
        }

    }

    componentWillUnmount() {
        this.unObserver();
    }

    observer() {

        if (isSupportNativeLoading) {
            return;
        }

        const imageElement = this.el.current;

        if (!imageElement) {
            return;
        }

        // 如果 src 不存在，则不进行观察
        if (!this.props.src) {
            return;
        }

        lazyImageObserver.observe(imageElement);

    }

    unObserver() {

        if (isSupportNativeLoading) {
            return;
        }

        const imageElement = this.el.current;

        if (!imageElement) {
            return;
        }

        // 如果已经有了 src，不用再取消观察
        if (imageElement.src) {
            return;
        }

        lazyImageObserver.unobserve(imageElement);

    }

    render() {

        const { props } = this;

        const { className, src = '', srcset = '', ...resetProps } = props;

        if (isSupportNativeLoading) {
            return (
                <img
                    alt=""
                    className={classNames('lazy', className)}
                    loading="lazy"
                    src={LazyImage.removeProtocol(src)}
                    srcSet={LazyImage.removeProtocol(srcset)}
                    ref={this.el}
                    {...resetProps}
                />
            );
        }

        // 部分不支持IntersectionObserver 直接展示图片
        if (!window.IntersectionObserver) {
            return (
                <img
                    alt=""
                    className={classNames('lazy', className)}
                    src={LazyImage.removeProtocol(src)}
                    {...resetProps}
                />
            );
        }

        return (
            <img
                alt="图片"
                className={classNames('lazy', className)}
                data-src={LazyImage.removeProtocol(src)}
                data-srcset={LazyImage.removeProtocol(srcset)}
                {...resetProps}
                ref={this.el}
            />
        );
    }

}

export { LazyImage };
