
type PxToTaroRem = (size:number) => number
/**
 * 计算taro组件具体像素值。
 * 因为taro和兔小巢计算根节点rem比例的方法不一样，这里需要重新计算下
 */
const pxToTaroRem:PxToTaroRem = (size) => {
  const WEAPP_RATIO = 2;
  // 小程序有pxToRem 不需要转多一次
  if (process.env.TARO_ENV === 'weapp') {
    return size * WEAPP_RATIO;
  }
  const TXC_DEFAULT_FS = 50;
  const TARO_DEFAULT_FS = 40;
  const TXC_BASE_WIDTH = 750;
  const TARO_BASE_WIDTH = 600;
  // 计算基数
  const TXC_BASE = 100 / TXC_BASE_WIDTH;
  const TARO_BASE = 0.125;
  const docEl = document.documentElement;
  let txcBaseFontSize = TXC_DEFAULT_FS;
  if (docEl.clientWidth <= TXC_BASE_WIDTH) {
    txcBaseFontSize = TXC_BASE * docEl.clientWidth;
  }

  let taroBaseFontSize = TARO_DEFAULT_FS;
  if (docEl.clientWidth <= TARO_BASE_WIDTH) {
    taroBaseFontSize = docEl.clientWidth * TARO_BASE;
  }

  return size * taroBaseFontSize / txcBaseFontSize;
};
export default pxToTaroRem;
