/* eslint-disable react/prop-types */
import React, { Component, Fragment, forwardRef, createRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import './dropdown.less';

const Dropdown = forwardRef((props, ref) => (
    <div className={classNames('dropdown', props.className)} style={{ left: props.x, top: props.y }} ref={ref}>
        {props.children}
    </div>
));

Dropdown.displayName = "WithdropdownInner";

/**
 * 递归查找子元素是否在父元素中
 * @param {HTMLElement} child
 * @param {HTMLElement} parent
 */
const isContain = function (child, parent) {
    let node = child;
    while (node) {
        if (node === parent) {
            return true;
        }

        // parentElement 在 IE 下面的 SVG 会找不到父元素
        // parentNode 支持 IE 下面的 SVG 元素
        node = node.parentNode;
    }

    return false;
};

const withDropdown = function (InnerComponent) {
    class OuterComponent extends Component {
        constructor(props) {
            super(props);

            this.state = {
                is_show: false,
                x: 0,
                y: 0,
                horizontal: 'left',
                vertical: 'top'
            };

            // 跟随的目标元素
            this.followEle = createRef();
            // 自身元素
            this.el = createRef();

            this.onClickWindow = this.onClickWindow.bind(this);
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const { state } = this;

            if (!prevState.is_show && state.is_show) {
                this.initPosition();
                window.addEventListener('click', this.onClickWindow, false);
            }

            if (prevState.is_show && !state.is_show) {
                window.removeEventListener('click', this.onClickWindow, false);
            }
        }

        componentWillUnmount() {
            window.removeEventListener('click', this.onClickWindow, false);
        }

        componentDidCatch(error, errorInfo) {
            console.error(error, errorInfo);
        }

        /**
         * 全局的点击事件处理
         * 如果点击的元素既不是触发元素，也不是 dropdown 内元素，则隐藏 dropdown
         * @param event
         */
        onClickWindow(event) {
            const target = event.target;

            const isClickInSide = isContain(target, this.el.current);
            const isClickTrigger = isContain(target, this.followEle.current);

            if (isClickInSide || isClickTrigger) {
                return;
            }

            this.setState({ is_show: false });
        }

        /**
         * 点击触发元素，展开或隐藏 dropdown
         * @param event
         */
        onClickTrigger(event) {
            this.setState(preState => {
                return { is_show: !preState.is_show };
            });
            this.props.onClick && this.props.onClick();
        }

        initPosition() {
            const { props, state } = this;

            if (!this.followEle.current || !this.el.current) {
                return;
            }
            // 浮层元素坐标
            let x;
            let y;
            let { vertical } = state;

            const { horizontal } = state;

            // 目标跟随元素
            const followElePosition = this.followEle.current.getBoundingClientRect();

            // 浮层元素
            const elPosition = this.el.current.getBoundingClientRect();

            // 浮层元素的尺寸
            const width = elPosition.right - elPosition.left;
            const height = elPosition.bottom - elPosition.top;

            // 浏览器 viewport 宽高
            const vpHeight = window.innerHeight;
            const vpWidth = window.innerWidth;

            const scrollY = window.scrollY || window.pageYOffset;

            if (this.props.hasParentEle) {
                x = 0;
                y = '100%';

                // 如果跟随下方放不下，则放到元素上方
                if (followElePosition.bottom + height > vpHeight) {
                    y = -90;
                    vertical = 'bottom';
                }

                this.setState({ x, y, horizontal, vertical });
                return;
            }

            // 默认放在元素下方
            y = followElePosition.bottom + scrollY;

            // 如果跟随下方放不下，则放到元素上方
            if (followElePosition.bottom + height > vpHeight) {
                y = followElePosition.top - height + scrollY;
                vertical = 'bottom';
            }

            // 默认左对齐
            x = followElePosition.left;

            // 如果浮层溢出了视窗外，则改为右对齐
            if (followElePosition.left + width > vpWidth) {
                x = followElePosition.right;
                vertical = 'right';
            }

            this.setState({ x, y, horizontal, vertical });
        }

        close() {
            this.setState({ is_show: false });
        }

        render() {
            const { state } = this;

            // 隔离 children 和 props，
            // props 给目标组件，children 传递给 Dropdown 组件
            const { children, ...props } = this.props;
            const parentEle = props.hasParentEle ? this.followEle.current : document.body;

            return (
                <Fragment>
                    <InnerComponent
                        {...props}
                        focused={state.is_show}
                        onClick={event => this.onClickTrigger(event)}
                        ref={this.followEle}
                    />

                    {state.is_show && createPortal(
                        <Dropdown
                            x={state.x}
                            y={state.y}
                            ref={this.el}
                            className={`${state.horizontal} ${state.vertical}`}
                        >
                            {children}
                        </Dropdown>
                        , parentEle)}
                </Fragment>
            );
        }
    }

    OuterComponent.displayName = `WithDropdown${InnerComponent.name || 'Component'}`;

    return OuterComponent;
};

export { withDropdown };
