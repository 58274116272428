import React from 'react';
import Tag from 'components/tag';

import './btns-style.less';
export const BtnsStyle = (props) => {
    return (
        <Tag.div className={props.isMobile ? 'btns_style_mobile' : 'btns_style_pc'}>
            {
                props.children
            }
        </Tag.div>
    );
};

BtnsStyle.defaultProps = {
    isMobile: false
};

