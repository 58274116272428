import React from 'react';
import classNames from 'classnames';
import { ariaRole } from 'components/util/aria';
import Tag from 'components/tag';
import Icon from 'components/icon';
import PropTypes from 'prop-types';

export const BtnLike = (props) => (
  <Tag.div
    needAuth
    className={classNames('btn btn-like', { active: props.isLiked })}
    onClick={(event) => props.onClick(event)}
    {...ariaRole('button')}
    tabIndex="0"
    aria-label={`${props.like_count ? props.like_count : props.default_count}赞 ${props.isLiked ? '已点赞' : ''}`}
  >
    {props.isLiked ? (
      <Icon className="tcIcon_liked" type="verygood-line" />
    ) : (
      <Icon className="tcIcon_likes" type="good-line" />
    )}
    <Tag.span className="btn-text">{props.like_count ? props.like_count : props.default_count}</Tag.span>
  </Tag.div>
);

BtnLike.defaultProps = {
  isLiked: false,
  like_count: 0,
  default_count: '赞',
  onClick: () => console.log('onClick 未绑定'),
};

BtnLike.propTypes = {
  isLiked: PropTypes.bool,
  like_count: PropTypes.number,
  default_count: PropTypes.string,
  onClick: PropTypes.func,
};
