import React from 'react';
import classnames from 'classnames';
import Tag from 'components/tag';
import './style.less';

/**
 * 小程序实现需要比较多操作这里不太一致
 */
const RichText = React.forwardRef(({ content, className, previewImg, isText, ...props }, ref) => {

    const formatContent = content
        // 修复富文本图片alt是地址的问题
        .replace(/<img(.*?)>/g, (_, attr) => `<img alt="文章相关" ${attr} />`);
    const params = {
        className: classnames('rich-text', 'skeleton-multi', className),
        key: !formatContent, // NOTE 这里需要key去触发强制刷新
        dangerouslySetInnerHTML: { __html: formatContent },
        ref,
        ...props,
    };
    return isText ? <Tag.span {...params} /> : <Tag.div {...params} />;
});
export default RichText;